import { AxiosRequestConfig } from 'axios';
import Api from '~/src/helpers/http-common';
import { IResponseListData } from '../interfaces/common';

export const upload = async (
    files: FileList | File[],
    group: string,
    index: number,
    config?: AxiosRequestConfig,
): Promise<IFilestore[]> => {
    return await Api.post(
        `/filestore/${group}/${index}`,
        null,
        { files },
        config,
    );
};

export const uploadRealty = async (
    files: FileList | File[],
    group: string,
    index: number,
    config?: AxiosRequestConfig,
): Promise<IFilestore[]> => {
    return await Api.post(
        `/filestore/upload-realty-image/${group}/${index}`,
        null,
        { files },
        config,
    );
};

export const sort = async (name: string[]): Promise<any> => {
    return await Api.post('/filestore/sort', { name });
};

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IFilestore>> => {
    return await Api.get(
        `/filestore?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export interface IFilestore {
    name: string;
    size: number;
    mime: string;
    type: string;
    extension: string;
    imageWidth: string | number;
    imageHeight: string | number;
    title: string | any;
    queue: number;
    entryIndex: number;
    entryGroup: string | any;
    isEnabled: boolean;
    verified: boolean;
    verifiedAt?: Date | null;
    verifiedBy?: string | null;
    createdAt?: Date;
    createdBy?: string;
    updatedAt?: Date;
    updatedBy?: string;
}
