import React from 'react';
import { useSelector } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { FormApi } from 'final-form';
import { useForm } from 'react-final-form';

import InputField from '../../../Common/Form/InputField';

import {
    composeValidators,
    required,
    price,
    requiredEmpty,
} from '~/src/helpers/validators';

import TextareaField from '../../../Common/Form/TextareaField';
import InputFieldError from '../../../Common/Form/InputFieldError';
import SimpleSelectV2Field from '../../../Common/Form/SimpleSelectV2';
import { suburbanMaterial } from '~/src/common/data/suburban.material';

import { selectSUB } from '~/src/store/realtySuburban/realtySuburbanSlice';
import { suburbanHeating } from '~/src/common/data/realtySuburban/realty.suburban.heating';
import { suburbanWaterSuply } from '~/src/common/data/realtySuburban/realty.suburban.waterSuply';
import { suburbanRenovation } from '~/src/common/data/realtySuburban/realty.suburban.renovation';

const UpdateFlatParamsView = (): JSX.Element => {
    const form: FormApi = useForm();
    const { item } = useSelector(selectSUB);

    const getHouseValidator = () =>
        form.getState().values.type !== 'land' ? required : undefined;

    return (
        <React.Fragment>
            <Row>
                {item?.megasale ? (
                    <InputFieldError
                        label='Цена'
                        name='price'
                        type='text'
                        errorMessage={
                            'Объект на распродаже, менять цену запрещено'
                        }
                        onChange={(e) =>
                            form.mutators.setPriceValue('price', e.target.value)
                        }
                        validators={composeValidators(required, price)}
                    />
                ) : (
                    <InputField
                        label='Цена'
                        name='price'
                        type='text'
                        onChange={(e) =>
                            form.mutators.setPriceValue('price', e.target.value)
                        }
                        validators={composeValidators(required, price)}
                    />
                )}
            </Row>
            <Row>
                <Col>
                    <InputField
                        label='Общая площадь участка (сотки)'
                        name='area'
                        type='number'
                        validators={composeValidators(required)}
                    />
                </Col>
                <Col>
                    <SimpleSelectV2Field
                        label='Газ'
                        name='gas'
                        validators={composeValidators(requiredEmpty)}
                    >
                        <option key={`gas-${''}`} value={''}></option>
                        <option key={`gas-${0}`} value={'false'}>
                            Нет
                        </option>
                        <option key={`gas-${1}`} value={'true'}>
                            Да
                        </option>
                    </SimpleSelectV2Field>
                </Col>
            </Row>
            {
                form.getState().values.type !== 'land' && <Row>
                    <Col>
                        <InputField
                            type='number'
                            label='Общая площадь дома'
                            name='houseSpaceTotal'
                            validators={getHouseValidator()}
                            key={getHouseValidator()
                                ? 'houseSpaceTotal'
                                : '_houseSpaceTotal'}
                        />
                    </Col>
                    <Col>
                        <SimpleSelectV2Field
                            label='Отопление'
                            name='heating'
                            validators={getHouseValidator()}
                            key={getHouseValidator() ? 'heating' : '_heating'}
                        >
                            {suburbanHeating.map((item) => (
                                <option
                                    key={`heating-${item.value}`}
                                    value={item.value}
                                >
                                    {item.title}
                                </option>
                            ))}
                        </SimpleSelectV2Field>
                    </Col>
                </Row>
            }
            <Row>
                {
                    form.getState().values.type !== 'land' && <Col>
                        <InputField
                            label='Этажей в доме'
                            name='houseStoreys'
                            validators={getHouseValidator()}
                            key={
                                getHouseValidator()
                                    ? 'houseStoreys'
                                    : '_houseStoreys'
                            }
                        />
                    </Col>
                }
                <Col>
                    <SimpleSelectV2Field
                        label='Водоснабжение'
                        name='waterSuply'
                    >
                        {suburbanWaterSuply.map((item) => (
                            <option
                                key={`waterSuply-${item.value}`}
                                value={item.value}
                            >
                                {item.title}
                            </option>
                        ))}
                    </SimpleSelectV2Field>
                </Col>
                {
                    form.getState().values.type == 'land' && <Col>
                        <SimpleSelectV2Field
                            label='Электричество'
                            name='electricity'
                            validators={composeValidators(requiredEmpty)}
                        >
                            <option key={`electricity-${''}`} value={null}></option>
                            <option key={`electricity-${0}`} value={'false'}>
                                Нет
                            </option>
                            <option key={`electricity-${1}`} value={'true'}>
                                Да
                            </option>
                        </SimpleSelectV2Field>
                    </Col>
                }
            </Row>
            {
                form.getState().values.type !== 'land' && <>
                    <Row>
                        <Col>
                            <SimpleSelectV2Field
                                label='Материал дома'
                                name='houseMaterial'
                                validators={getHouseValidator()}
                                key={
                                    getHouseValidator()
                                        ? 'houseMaterial'
                                        : '_houseMaterial'
                                }
                            >
                                {suburbanMaterial.map((item) => (
                                    <option
                                        key={`suburbanMaterial-${item.value}`}
                                        value={item.value}
                                    >
                                        {item.title}
                                    </option>
                                ))}
                            </SimpleSelectV2Field>
                        </Col>
                        <Col>
                            <SimpleSelectV2Field
                                label='Ремонт'
                                name='renovation'
                                validators={getHouseValidator()}
                                key={getHouseValidator() ? 'renovation' : '_renovation'}
                            >
                                {suburbanRenovation.map((item) => (
                                    <option
                                        key={`suburbanRenovation-${item.value}`}
                                        value={item.value}
                                    >
                                        {item.title}
                                    </option>
                                ))}
                            </SimpleSelectV2Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputField
                                label='Кол-во спальных комнат'
                                name='houseRooms'
                                type='text'
                                validators={getHouseValidator()}
                            />
                        </Col>
                        <Col>
                            <SimpleSelectV2Field
                                label='Электричество'
                                name='electricity'
                                validators={composeValidators(requiredEmpty)}
                            >
                                <option key={`electricity-${''}`} value={null}></option>
                                <option key={`electricity-${0}`} value={'false'}>
                                    Нет
                                </option>
                                <option key={`electricity-${1}`} value={'true'}>
                                    Да
                                </option>
                            </SimpleSelectV2Field>
                        </Col>
                    </Row>
                </>
            }
            <TextareaField
                label='Описание'
                name='description'
                validators={required}
            />
            <TextareaField label='Заметка для себя' name='privateNote' />
        </React.Fragment>
    );
};

export default UpdateFlatParamsView;
