import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectAuth } from '~/src/store/authSlice';
import UpdateView from '~/src/components/Users/update';
import { getUser, selectUsers } from '~/src/store/usersSlice';
import { setActiveMenuKey } from '~/src/store/appSlice';

const UpdatePage = (): JSX.Element => {
    const dispatch = useDispatch();

    const { me } = useSelector(selectAuth);
    const { loading } = useSelector(selectUsers);

    useEffect(() => {
        dispatch(getUser(+me.id));
    }, [me.id, dispatch]);

    useEffect(() => {
        dispatch(setActiveMenuKey('PA'));
    }, [dispatch]);

    return (
        <React.Fragment>
            {loading ? <div>Загрузка...</div> : <UpdateView />}
        </React.Fragment>
    );
};

export default UpdatePage;
