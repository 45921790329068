import React, { BaseSyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import { ownBtns } from '~/src/common/data/search.form';
import { selectAuth } from '~/src/store/authSlice';
import { TAppDispatch, TState } from '~/src/store';

interface IData {
    name: string;
    value: string;
    variantOff: string;
    variantOn: string;
}

interface IOwnBtns {
    formFilter: any;
    setFormFilter: any;
    goTo: (
        params: Record<string, any>,
    ) => (dispatch: TAppDispatch, getState: () => TState) => void;
    itemsOut?: number;
    data?: IData[]
}

const OwnBtns = ({ formFilter, setFormFilter, goTo, itemsOut, data }: IOwnBtns) => {
    const dispatch = useDispatch();

    const { me } = useSelector(selectAuth);
    const handleChangeValue = (e: BaseSyntheticEvent) => {
        setFormFilter((prevFormFilter) => {
            const updatedFormFilter = {
                ...prevFormFilter,
                [e.target.name]: e.target.value,
                sort: 'created_at_desc',
            };
            dispatch(goTo(updatedFormFilter));
            return updatedFormFilter;
        });
    };
    return (
        <>
            <Form.Group className='d-flex flex-column'>
                <Form.Label>База</Form.Label>
                <ButtonGroup>
                    {data.map((radio, idx) => {
                        return (radio.value === 'my_agency' &&
                            me?.agencyInfo) ||
                            radio.value !== 'my_agency' ? (
                            <ToggleButton
                                key={idx}
                                size='sm'
                                id={`own-btns-${idx}`}
                                type='radio'
                                variant={
                                    formFilter.own === radio.value
                                        ? radio.variantOn
                                        : radio.variantOff
                                }
                                name='own'
                                value={radio.value}
                                checked={formFilter.own === radio.value}
                                onChange={handleChangeValue}
                            >
                                {itemsOut !== null &&
                                radio.value === 'my_outdated_soon'
                                    ? `${radio.name} (${itemsOut})`
                                    : radio.name}
                            </ToggleButton>
                        ) : null;
                    })}
                </ButtonGroup>
            </Form.Group>
        </>
    );
};

export default OwnBtns;
