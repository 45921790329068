import React, { FC } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import PhotoSlider from '../../Common/Filestore/Photos/PhotoSlider';
import HaveAccess from '../../Common/HaveAccess';
import { DEVELOPER_PERMISSION_HOUSE_PROJECT_CREATE } from './constants';
import { IDeveloperHouseProject } from '~/src/service/developer/developer.house.project';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import { useDispatch, useSelector } from 'react-redux';
import { remove } from '~/src/store/developer/developer.house.project';
import { selectAuth } from '~/src/store/authSlice';
import ButtonWithConfirm from '../../Common/ModalUI/ButtonWithConfirm';
import AuthLayer from '../../Common/Hoc';

const ListRow: FC<{ item: IDeveloperHouseProject }> = ({ item }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const { me } = useSelector(selectAuth);

    // const handleHouseProjectCottageVillage = () => {
    //     dispatch(actionHouseProjectCottageVillage(item.id, Number(searchParams.get('cottageVillageId')), searchParams.get('titleCV')));
    // };
    const deleteDHP = () => dispatch(remove(item?.id));

    return (
        <React.Fragment>
            <tr>
                <td>{item.id}</td>
                <td>
                    <p>{item.title}</p>
                    <PhotoSlider photos={item?.photos || []} />
                    {
                        item.exclusiveForCv &&
                        <p>
                            <strong>
                                Эксклюзивно для {item?.cottageVillages[0]?.title}
                            </strong>
                        </p>
                    }
                    {
                        item.area &&
                        <p>
                            <strong>
                                С участком {item?.area} сот.
                            </strong>
                        </p>
                    }
                </td>
                <td>
                    {
                        (item.owner?.firstname && item.owner?.lastname && item.owner?.middlename) &&
                        <p>
                            <strong>
                                {`${item.owner?.lastname} ${item.owner?.firstname} ${item.owner?.middlename}`}
                            </strong>
                        </p>
                    }
                    <p>
                        {
                            phoneFormatter(item.owner?.phone)
                        }
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Цена</strong>
                        <br />
                        <span>
                            {new Intl.NumberFormat('ru-RU').format(item?.price)}
                        </span>
                    </p>
                    {
                        (item?.priceDiscount > 0) && <p>
                            <strong>Акционная цена</strong>
                            <br />
                            <span>
                                {new Intl.NumberFormat('ru-RU').format(item?.priceDiscount)}
                            </span>
                        </p>
                    }
                    <p>
                        <strong>Площадь общая</strong>
                        <br />
                        <span>от {item.spaceTotal}</span>
                    </p>
                    <p>
                        <strong>Материал</strong>
                        <br />
                        <span>
                            <FormattedMessage
                                id={`house_material_${item.material}`}
                            />
                        </span>
                    </p>
                    <p>
                        <strong>Этажность</strong>
                        <br />
                        <span>{item.storeys}</span>
                    </p>
                    <p>
                        <strong>Гараж</strong>
                        <br />
                        <span>
                            <FormattedMessage id={`garage_${item.garage}`} />
                        </span>
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Застройщик</strong>
                        <br />
                        <span>{item.developer?.title}</span>
                    </p>
                    <p>
                        <strong>Телефон</strong>
                        <br />
                        <span>
                            {phoneFormatter(item.developer?.contactPhone)}
                        </span>
                    </p>
                </td>
                <td>
                    <ButtonGroup vertical>
                        <HaveAccess
                            access={DEVELOPER_PERMISSION_HOUSE_PROJECT_CREATE}
                        >
                            <Link
                                to={`/developer-house-project/update/${item.id}`}
                                className='btn btn-sm btn-warning'
                                title='Редактировать'
                            >
                                Редактировать
                            </Link>
                        </HaveAccess>
                        {/* Пока оставить, может понадобится */}
                        {/* {
                            !item.exclusiveForCv &&
                            <HaveAccess
                                access={DEVELOPER_PERMISSION_HOUSE_PROJECT_CREATE}
                            >
                                {
                                    searchParams.has('cottageVillageId') &&
                                    <Button
                                        onClick={handleHouseProjectCottageVillage}
                                        variant={
                                            doesObjectExist(item.cottageVillages, Number(searchParams.get('cottageVillageId')))
                                                ? 'danger'
                                                : 'success'
                                        }
                                    >
                                        {
                                            doesObjectExist(item.cottageVillages, Number(searchParams.get('cottageVillageId')))
                                                ? 'Убрать'
                                                : 'Добавить'
                                        }
                                    </Button>
                                }
                            </HaveAccess>
                        } */}
                        <AuthLayer
                            position={['director', 'grand', 'administrator']}
                        >
                            <ButtonWithConfirm
                                modalText='Удалить?'
                                variant='danger'
                                label=''
                                onClick={deleteDHP}
                            >
                                <FontAwesomeIcon icon={['fas', 'trash']} />{' '}
                                Удалить
                            </ButtonWithConfirm>
                        </AuthLayer>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment >
    );
};

export default ListRow;
