import { useDispatch } from 'react-redux';
import { useAppSelector } from '~/src/helpers/hooks';

import { Form as FinalForm } from 'react-final-form';
import InputField from '../../Common/Form/InputField';
import ReactSelectField from '../../Common/Form/ReactSelectField';
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import ToggleButtonField from '../../Common/Form/ToggleButtonWrapper';

import { selectDeveloper } from '~/src/store/developer/developerSlice';
import { goTo, selectDHP } from '~/src/store/developer/developer.house.project';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';

import { suburbanGarageStatus } from '~/src/common/data/suburban.garage.status';
import CheckboxField from '../../Common/Form/CheckboxField';

const SearchForm = ({ showForm, submitRef }): JSX.Element => {
    const dispatch = useDispatch();
    const { search } = useAppSelector(selectDHP);
    const { unloadedSuburban: developers } = useAppSelector(selectDeveloper);

    const onSubmit = (values: Record<string, any>) => {
        dispatch(goTo({
            ...values,
            priceMin: values?.priceMin?.toString().replaceAll(/\D/g, ''),
            priceMax: values?.priceMax?.toString().replaceAll(/\D/g, ''),
            priceDiscountMin: values?.priceDiscountMin?.toString().replaceAll(/\D/g, ''),
            priceDiscountMax: values?.priceDiscountMax?.toString().replaceAll(/\D/g, ''),
        }));
    };

    const devChoosen =
        developers?.map((d) => ({
            value: d.id,
            label: d.title,
        })) || [];

    const suburbanGaragePrepared = suburbanGarageStatus.map((field) => ({
        label: field.title,
        value: field.value,
    }));

    if (showForm) {
        return (
            <FinalForm
                onSubmit={onSubmit}
                mutators={{
                    // expect (field, value) args from the mutator
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                    setPriceValue: ([field, value], state, { changeValue }) => {
                        value = value.replaceAll(/\D/g, '');
                        changeValue(state, field, () =>
                            new Intl.NumberFormat('ru-RU').format(value),);
                    },
                    setDecimalValue: (
                        [field, value],
                        state,
                        { changeValue },
                    ) => {
                        value = value.replaceAll(',', '.');
                        changeValue(state, field, () => value);
                    },
                    setSelectValue: (
                        [field, value],
                        state,
                        { changeValue },
                    ) => {
                        changeValue(state, field, () => [
                            ...value.map((v) => v.value),
                        ]);
                    },
                }}
                initialValues={{
                    ...createInitialFormValues(search),
                }}
                render={({ handleSubmit, form }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <ReactSelectField
                                        name='developerId'
                                        label={'Застройщик'}
                                        value={devChoosen?.filter((d) =>
                                            form
                                                .getState()
                                                .values?.developerId?.includes(
                                                    d.value,
                                                ),)}
                                        isMulti={true}
                                        onChange={(v) =>
                                            form.mutators.setSelectValue(
                                                'developerId',
                                                v,
                                            )
                                        }
                                        options={devChoosen}
                                    />
                                </Col>
                                <Col>
                                    <Form.Group className='mb-2'>
                                        <div>
                                            <Form.Label>Этажей</Form.Label>
                                        </div>
                                        <ButtonGroup>
                                            {[1, 2, 3].map((radio, idx) => (
                                                <ToggleButtonField
                                                    key={`storey-${idx}`}
                                                    label={`${radio}`}
                                                    name='storey'
                                                    size='sm'
                                                    type='checkbox'
                                                    value={radio}
                                                    variantChecked='primary'
                                                    variantUnChecked='outline-primary'
                                                />
                                            ))}
                                        </ButtonGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputField
                                        name='priceMin'
                                        label='Цена от'
                                        onChange={(e) =>
                                            form.mutators.setPriceValue('priceMin', e.target.value)
                                        }
                                    />
                                </Col>
                                <Col>
                                    <InputField
                                        name='priceMax'
                                        label='Цена до'
                                        onChange={(e) =>
                                            form.mutators.setPriceValue('priceMax', e.target.value)
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputField
                                        name='priceDiscountMin'
                                        label='Акционная цена от'
                                        onChange={(e) =>
                                            form.mutators.setPriceValue('priceDiscountMin', e.target.value)
                                        }
                                    />
                                </Col>
                                <Col>
                                    <InputField
                                        name='priceDiscountMax'
                                        label='Акционная цена до'
                                        onChange={(e) =>
                                            form.mutators.setPriceValue('priceDiscountMax', e.target.value)
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputField
                                        name='spaceTotalMin'
                                        label='Площадь от'
                                    />
                                </Col>
                                <Col>
                                    <InputField
                                        name='spaceTotalMax'
                                        label='Площадь до'
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputField
                                        name='bathroomTotalMin'
                                        label='Санузлов от'
                                    />
                                </Col>
                                <Col>
                                    <InputField
                                        name='bathroomTotalMax'
                                        label='Санузлов до'
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ReactSelectField
                                        name='garage'
                                        label={'Гараж'}
                                        value={suburbanGaragePrepared?.filter(
                                            (d) =>
                                                form
                                                    .getState()
                                                    .values?.garage?.includes(
                                                        d.value,
                                                    ),
                                        )}
                                        isMulti={true}
                                        onChange={(v) =>
                                            form.mutators.setSelectValue(
                                                'garage',
                                                v,
                                            )
                                        }
                                        options={suburbanGaragePrepared}
                                    />
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CheckboxField name='terrace' label='Терраса' />
                                </Col>
                                <Col>
                                    <CheckboxField name='isInstallment' label='Возможность строительства в рассрочку, без переплаты' />
                                    <CheckboxField name='isDiscountFullPay' label='Строительство со скидкой при единовременной полной оплате' />
                                    <CheckboxField name='isStateProgramm' label='Строительство по Государственным программам, сертификатам' />

                                </Col>
                                <Col>
                                    <CheckboxField name='isExchange' label='Возможность строительства с обменом на свою недвижимость' />
                                    <CheckboxField name='buildWoPv' label='Строительство без ПВ' />
                                </Col>
                            </Row>
                            <ButtonGroup className='mt-3 mb-3'>
                                <Button
                                    type='submit'
                                    size='sm'
                                    variant='primary'
                                    ref={submitRef}
                                >
                                    Найти
                                </Button>
                            </ButtonGroup>
                        </form>
                    );
                }}
            />
        );
    }

    return <></>;
};

export { SearchForm };
