import {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';
import {
    errorNotify,
    successNotify,
    warningNotify,
} from '../helpers/createNotify';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    // console.info(`[request] [${JSON.stringify(config)}]`);
    config.headers = {
        ...config.headers,
        'x-no-need-cache': 'yes',
    };
    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[request error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
    // console.info(`[response] [${JSON.stringify(response)}]`);

    const isStatusSuccess = response.status == 201 || response.status == 200;
    const isUnsafeMethod = ['post', 'put'].includes(response.config.method);
    const isResponseSuccess = isStatusSuccess && response.data.success;
    const isResponseUnSuccess = isStatusSuccess && !response.data.success;

    if (isUnsafeMethod && isResponseSuccess) {
        successNotify({ message: response.data.message ?? 'Успешно!' });
    }
    if (isUnsafeMethod && isResponseUnSuccess && response.data.errors) {
        response.data.errors.forEach((err) => {
            warningNotify({
                title: 'Ошибка!',
                message: `${err.tField}: ${err.message}`,
            });
        });
    }
    return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[response error] [${JSON.stringify(error)}]`);
    if (error && error.response && error.response.status === 400) {
        warningNotify({ message: error.response.data.message });
    }
    if (error && error.response && error.response.status === 401) {
        warningNotify({ message: 'Вы не авторизованы' });
    }
    if (error && error.response && error.response.status === 403) {
        warningNotify({ message: 'Недостаточно прав' });
    }

    if (
        error &&
        error.response &&
        (error.response.status === 500 || error.response.status === 503)
    ) {
        errorNotify({
            message: `Непредвиденная ошибка ${error.response.status}`,
        });
    }

    return Promise.reject(error);
};

export function setupInterceptorsTo(
    axiosInstance: AxiosInstance,
): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
}
