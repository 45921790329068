import { AxiosRequestConfig } from 'axios';
import Api from '~/src/helpers/http-common';
import { IFilestore } from '~/src/service/filestore';
import { IRSModel } from '../store/realtySecondary/realtySecondarySlice';
import { IResponseListData, IUpdateData } from '../interfaces/common';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IRSModel> | any> => {
    return await Api.get(
        `/realty/secondary?${queryParams != null ? `${queryParams.toString()}&_=${new Date().getTime()}` : ''}`,
    );
};

export const create = async (
    data: any,
    files?: Record<string, FileList>,
): Promise<IRSModel> => {
    return (await Api.post('/realty/secondary/', data, files)) as IRSModel;
};

export const read = async (id: number): Promise<IRSModel> => {
    return await Api.get(`/realty/secondary/${id}?_=${new Date().getTime()}`);
};

export const update = async (
    id: string | number,
    data: any,
    files?: Record<string, FileList | File[]>,
): Promise<IUpdateData<IRSModel>> => {
    return await Api.put<IUpdateData<IRSModel>>(
        `/realty/secondary/${id}`,
        data,
        files,
    );
};

export const deleteFile = async (
    id: string | number,
    name: string,
): Promise<IFilestore | any> => {
    const res = await Api.delete<IFilestore>(
        `/realty/secondary/${id}/files/${name}`,
    );

    return res;
};

export const checkDouble = async (
    addressPlaceDetailId: any,
    flatNumber: any,
    userUid: any,
): Promise<any> => {
    if (addressPlaceDetailId && flatNumber && userUid) {
        const data = {
            addressPlaceDetailId,
            flatNumber,
            userUid,
        };

        return await Api.post('/realty/secondary/check-double', data);
    }
};

export const uploadPhotos = async (
    id: number,
    files: FileList | File[],
    config?: AxiosRequestConfig,
): Promise<IFilestore[]> => {
    console.dir(files);
    return await Api.post(
        `/realty/secondary/upload-photo/${id}`,
        null,
        { files },
        config,
    );
};

export const favorite = async (id: string | number): Promise<any> => {
    return await Api.post(`/realty/secondary/favorite/${id}`);
};

export const fresh = async (id: string | number): Promise<any> => {
    return await Api.post(`/realty/secondary/fresh/${id}`);
};

export const sold = async (id: string | number): Promise<any> => {
    return await Api.post(`/realty/secondary/sold/${id}`);
};

export const soldOff = async (id: string | number): Promise<any> => {
    return await Api.post(`/realty/secondary/sold-off/${id}`);
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/realty/secondary/${id}`);
};

export const verify = async (id: string | number): Promise<any> => {
    return await Api.post(`/realty/secondary/verify/${id}`);
};

export const unverify = async (
    id: string | number,
    data: any,
): Promise<any> => {
    return await Api.post(`/realty/secondary/unverify/${id}`, data);
};

export const xmlfeedaction = async (
    id: string | number,
    data: Record<string, any>,
): Promise<any> => {
    return await Api.post(`/realty/secondary/xmlfeedaction/${id}`, data);
};

export const transfer = async (
    id: string | number,
    uid: string,
): Promise<IUpdateData<IRSModel & any>> => {
    return await Api.post(`/realty/secondary/transfer/${id}`, {
        agentUid: uid,
    });
};

export const navigate = async (id: string | number): Promise<any> => {
    return await Api.get(
        `/realty/secondary/navigate/${id}?_=${new Date().getTime()}`,
    );
};

export type UploadProps = {
    files: File[] | FileList;
    id?: number;
    onUploadProgress?: (e) => void;
};
