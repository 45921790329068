import dateFormat from 'dateformat';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { FormattedMessage } from 'react-intl';
import FigureImage from 'react-bootstrap/FigureImage';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import { IRSUBModel } from '~/src/store/realtySuburban/realtySuburbanSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import Comments from '../../../Comment';
import cianLogo from '~/src/assets/img/cian.png';
import avitoLogo from '~/src/assets/img/avito.png';
import yandexLogo from '~/src/assets/img/yandex.png';
import domclickLogo from '~/src/assets/img/domclick.png';
import IListUpdateInfo from '../IList/IListUpdateInfo';
import classNames from 'classnames';
import EncumbranceUnderstatementNeed from '../../CommonRealty/EncumbranceUnderstatementNeed';

export default function RSUBCard({
    item,
    handleFavorite,
}: {
    item: IRSUBModel;
    handleFavorite: () => void;
}): JSX.Element {
    const [index, setIndex] = useState(0);
    const [isOpen, setOpen] = useState(false);

    const base = 'https://files.citidom.com/';

    return (
        <React.Fragment>
            <Row>
                <Col xs={4}>
                    <React.Fragment>
                        <Card>
                            <Card.Header>Фотографии</Card.Header>
                            <Card.Body style={{ position: 'relative' }}>
                                <FigureImage
                                    src={`${base}${item.photos[0]?.name}`}
                                    onClick={() => setOpen(true)}
                                />
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={`${base}${item.photos[index]?.name}`}
                                        nextSrc={`${base}${item.photos[(index + 1) % item.photos.length]?.name}`}
                                        prevSrc={`${base}${item.photos[(index + item.photos.length - 1) % item.photos.length]?.name}`}
                                        onCloseRequest={() => {
                                            setOpen(false);
                                            setIndex(0);
                                        }}
                                        onMovePrevRequest={() =>
                                            setIndex(
                                                (index +
                                                    item.photos.length -
                                                    1) %
                                                item.photos.length,
                                            )
                                        }
                                        onMoveNextRequest={() =>
                                            setIndex(
                                                (index + 1) %
                                                item.photos.length,
                                            )
                                        }
                                    />
                                )}
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '2rem',
                                        left: '2rem',
                                    }}
                                >
                                    {item.isFavorite ? (
                                        <Button
                                            size='sm'
                                            variant='primary'
                                            title='Убрать из избранного'
                                            onClick={() => handleFavorite()}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'heart']}
                                            />
                                        </Button>
                                    ) : (
                                        <Button
                                            size='sm'
                                            variant='outline-primary'
                                            title='Добавить в избранное'
                                            onClick={() => handleFavorite()}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'heart']}
                                            />
                                        </Button>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                        <br></br>
                    </React.Fragment>
                    {/* {item?.photoCount > 0
                        ? <React.Fragment>
                            <Card>
                                <Card.Header>Фотографии</Card.Header>
                                <Card.Body style={{ position: 'relative' }}>
                                    <FigureImage
                                        src={`${base}${item.photos[0].name}`}
                                        onClick={() => setOpen(true)}
                                    />
                                    {isOpen && (
                                        <Lightbox
                                            mainSrc={`${base}${item.photos[index].name}`}
                                            nextSrc={`${base}${item.photos[(index + 1) % item.photos.length].name}`}
                                            prevSrc={`${base}${item.photos[(index + item.photos.length - 1) % item.photos.length].name}`}
                                            onCloseRequest={() => {
                                                setOpen(false);
                                                setIndex(0);
                                            }}
                                            onMovePrevRequest={() => setIndex((index + item.photos.length - 1) % item.photos.length)}
                                            onMoveNextRequest={() => setIndex((index + 1) % item.photos.length)}
                                        />
                                    )}
                                    <div style={{ position: 'absolute', top: '2rem', left: '2rem' }}>
                                        {item.isFavorite
                                            ? <Button
                                                size='sm'
                                                variant='primary'
                                                title='Убрать из избранного'
                                                onClick={() => handleFavorite()}
                                            >
                                                <FontAwesomeIcon icon={['fas', 'heart']} />
                                            </Button>
                                            : <Button
                                                size='sm'
                                                variant='outline-primary'
                                                title='Добавить в избранное'
                                                onClick={() => handleFavorite()}
                                            >
                                                <FontAwesomeIcon icon={['fas', 'heart']} />
                                            </Button>
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                            <br></br>
                        </React.Fragment>
                        : null } */}
                    <Card>
                        <Card.Header>Информация</Card.Header>
                        <Card.Body>
                            <p>
                                Внесен:{' '}
                                {dateFormat(
                                    item.createdAt,
                                    'dd.mm.yyyy hh:MM:ss',
                                )}
                                <br />
                                {/* {item.fullfiedAt && `Заполнены все поля: ${dateFormat(item.fullfiedAt, 'dd.mm.yyyy hh:MM:ss')}`}
                                {item.fullfiedAt && <br />} */}
                                <IListUpdateInfo item={item} />В базе еще{' '}
                                {item.freshAgo > 1
                                    ? `${item.freshAgo} дней`
                                    : `${item.freshAgoH} часов`}
                            </p>
                            {/* {item.xmlfeedunload && item.xmlfeedunload.length > 0 &&
                                <React.Fragment>
                                    {item.xmlfeedunload.map(feed => {
                                        if (feed.enabled) {
                                            switch (feed.feed?.type) {
                                                case 'avito':
                                                    return <img src={avitoLogo} width={16} height={16} title={feed.feed.title} style={{ marginRight: '5px' }} />;
                                                case 'cian':
                                                    return <img src={cianLogo} width={16} height={16} title={feed.feed.title} style={{ marginRight: '5px' }} />;
                                                case 'domclick':
                                                    return <img src={domclickLogo} width={16} height={16} title={feed.feed.title} style={{ marginRight: '5px' }} />;
                                                case 'yandex':
                                                    return <img src={yandexLogo} width={16} height={16} title={feed.feed.title} style={{ marginRight: '5px' }} />;
                                            }
                                        }
                                    })}
                                </React.Fragment>
                            } */}
                        </Card.Body>
                    </Card>
                    <br></br>
                    <Card>
                        <Card.Header>Продавец</Card.Header>
                        <Card.Body>
                            {item.agentInfo?.agencyInfo?.agency && (
                                <p>
                                    <strong>Агентство</strong>
                                    <br></br>
                                    <span>
                                        {
                                            item.agentInfo?.agencyInfo?.agency
                                                .title
                                        }
                                    </span>
                                </p>
                            )}
                            <p>
                                <strong>
                                    {item?.agentInfo?.role != 'agent'
                                        ? 'Собственник'
                                        : 'Агент'}
                                </strong>
                                <br></br>
                                <span>{item.agentInfo?.fullname}</span>
                            </p>
                            <p>
                                <strong>Телефон</strong>
                                <br></br>
                                <span>
                                    {phoneFormatter(
                                        item.agentInfo?.phone.toString(),
                                    )}
                                </span>
                            </p>
                        </Card.Body>
                    </Card>
                    <br></br>
                </Col>
                <Col xs={8}>
                    <Card>
                        <Card.Header>Основные характеристики</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <p>
                                        <strong>ID</strong>
                                        <br />
                                        <span>{item.id}</span>
                                    </p>
                                    <p>
                                        <strong>Загородная недвижимость</strong>
                                    </p>
                                    <p>
                                        <strong>Тип</strong>
                                        <br />
                                        <span>
                                            <FormattedMessage
                                                id={`realty_suburban_type_${item.type}`}
                                            />
                                        </span>
                                    </p>
                                    <p>
                                        <strong>Назначение участка</strong>
                                        <br />
                                        <span>
                                            <FormattedMessage
                                                id={`realty_suburban_landAppointment_${item.landAppointment}`}
                                            />
                                        </span>
                                    </p>
                                    <p>
                                        <strong>Адрес</strong>
                                        <br />
                                        <span>
                                            {
                                                item?.addressDetail
                                                    ?.formattedAddress
                                            }
                                        </span>
                                    </p>
                                    {item.cottageVillage && (
                                        <p>
                                            <strong>Коттеджный поселок</strong>
                                            <br />
                                            <span>
                                                {item?.cottageVillage?.title}
                                            </span>
                                        </p>
                                    )}
                                    <p>
                                        <strong>Тракт</strong>
                                        <br />
                                        <span>{item?.direction?.title}</span>
                                    </p>
                                    <p>
                                        <strong>Кадастровый номер:</strong>
                                        <br />
                                        {item.cn}
                                    </p>
                                </Col>
                                <Col>
                                    <p>
                                        <strong>Материал</strong>
                                        <br></br>
                                        <span>
                                            <FormattedMessage
                                                id={`house_material_${item.houseMaterial}`}
                                            />
                                        </span>
                                    </p>
                                    {item.houseStoreys && (
                                        <p>
                                            <strong>Этажей в доме</strong>
                                            <br></br>
                                            <span>{item.houseStoreys}</span>
                                        </p>
                                    )}
                                    {item.houseRooms && (
                                        <p>
                                            <strong>
                                                Кол-во спальных комнат:
                                            </strong>
                                            <br></br>
                                            {item.houseRooms}
                                        </p>
                                    )}
                                    {item.renovation && (
                                        <p>
                                            <strong>Ремонт</strong>
                                            <br></br>
                                            <span>
                                                <FormattedMessage
                                                    id={`realty_secondary_renovation_${item.renovation}`}
                                                />
                                            </span>
                                        </p>
                                    )}
                                </Col>
                                <Col>
                                    {item.area && (
                                        <p>
                                            <strong>Площадь участка</strong>
                                            <br></br>
                                            <span>{item.area}</span>
                                        </p>
                                    )}
                                    {item.houseSpaceTotal && (
                                        <p>
                                            <strong>Площадь дома</strong>
                                            <br></br>
                                            <span>{item.houseSpaceTotal}</span>
                                        </p>
                                    )}
                                    {item.fence && (
                                        <p>
                                            <strong>{item.fence}</strong>
                                        </p>
                                    )}
                                    {item.heating && (
                                        <p>
                                            <strong>Отопление</strong>
                                            <br></br>
                                            <span>
                                                <FormattedMessage
                                                    id={`realty_suburban_heating_${item.heating}`}
                                                />
                                            </span>
                                        </p>
                                    )}
                                    {item.waterSuply && (
                                        <p>
                                            <strong>Водоснабжение</strong>
                                            <br></br>
                                            <span>
                                                <FormattedMessage
                                                    id={`realty_suburban_waterSuply_${item.waterSuply}`}
                                                />
                                            </span>
                                        </p>
                                    )}
                                    {item.electricity && (
                                        <p>
                                            <strong>Электричество</strong>
                                        </p>
                                    )}
                                    {item.gas && (
                                        <p>
                                            <strong>Газ</strong>
                                        </p>
                                    )}
                                </Col>
                                <Col>
                                    <p>
                                        <strong>Цена</strong>
                                        <br></br>
                                        <span>
                                            {new Intl.NumberFormat(
                                                'ru-RU',
                                            ).format(+item.price)}
                                        </span>
                                        <br></br>
                                        <strong>
                                            Цена за м<sup>2</sup>
                                        </strong>
                                        <br></br>
                                        <span>
                                            {new Intl.NumberFormat(
                                                'ru-RU',
                                            ).format(
                                                Math.floor(
                                                    +item.price / +item.area,
                                                ),
                                            )}
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <br></br>
                    <Card>
                        <Card.Header>Дополнительные характеристики</Card.Header>
                        <Card.Body className='flex-wrap'>
                            {item.sauna && <Badge className='me-1'>Баня</Badge>}
                            {item.garage && <Badge className='me-1'>Гараж</Badge>}
                            {item.carport && <Badge className='me-1'>Навес для машины</Badge>}
                            {item.fence && <Badge className='me-1'>Участок огорожен</Badge>}
                            {item.isClosedArea && (
                                <Badge className='me-1'>
                                    Закрытая территория
                                </Badge>
                            )}
                            {item.isProtectedArea && (
                                <Badge className='me-1'>
                                    Охраняемая территория
                                </Badge>
                            )}
                            {item.sellerMinors > 0 && (
                                <Badge className='me-1'>
                                    Несовершеннолетние собственники
                                </Badge>
                            )}
                            {item.terrace && (
                                <Badge className='me-1'>
                                    Терраса
                                </Badge>
                            )}
                            {item.hasSportGround && (
                                <Badge className='me-1'>Спортивная площадка</Badge>
                            )}
                            {item.hasBesideSchool && (
                                <Badge className='me-1'>Рядом школа</Badge>
                            )}
                            {item.hasBesidePreSchool && (
                                <Badge className='me-1'>Рядом д. сад</Badge>
                            )}
                            {/* {{item.hasBesideMall && <Badge className='me-1'>Рядом ТЦ</Badge>} */}
                            {item.hasBesideWater && (
                                <Badge className='me-1'>Рядом водоем</Badge>
                            )}
                            {item.hasBesideForest && (
                                <Badge className='me-1'>Рядом лес</Badge>
                            )}
                            {item.hasBesidePark && (
                                <Badge className='me-1'>Рядом парк</Badge>
                            )}
                            {item.isExclusive && (
                                <Badge className='bg-success'>
                                    Экс.
                                </Badge>
                            )}
                            {item.fromLegalEntity && (
                                <Badge className='bg-success'>
                                    От юр. лица
                                </Badge>
                            )}
                            {item?.isDiscount && <Badge className='bg-success'>Промо</Badge>}
                            {item?.ruralMortgage && <Badge className='bg-success'>Сельская</Badge>}
                            <div className='d-flex gap-3 mt-3'>
                                <EncumbranceUnderstatementNeed item={item}/>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <br></br>
                    <Card>
                        <Card.Header>Описание</Card.Header>
                        <Card.Body>{item.description}</Card.Body>
                    </Card>
                    <br></br>
                    {item.isMy && (
                        <Card>
                            <Card.Header>Для себя</Card.Header>
                            <Card.Body>{item.privateNote}</Card.Body>
                        </Card>
                    )}
                    <Comments section='realty_suburban' entry={item.id} />
                </Col>
            </Row>
        </React.Fragment>
    );
}
