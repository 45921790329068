import { AxiosRequestConfig } from 'axios';
import Api from '~/src/helpers/http-common';
import { IFilestore } from '~/src/service/filestore';
import { IResponseListData, IUpdateData } from '../interfaces/common';
import {
    IRSUBModel,
    ISUBMapModel,
} from '../store/realtySuburban/realtySuburbanSlice';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IRSUBModel> | any> => {
    return await Api.get(
        `/realty/suburban?${queryParams != null ? `${queryParams.toString()}` : ''}`,
    );
};

export const allCoordinates = async (
    queryParams?: URLSearchParams,
): Promise<ISUBMapModel[]> => {
    return await Api.get(
        `/realty/suburban/map?${queryParams != null ? `${queryParams.toString()}` : ''}`,
    );
};

export const create = async (
    data: any,
    files?: Record<string, FileList>,
): Promise<IRSUBModel> => {
    return await Api.post('/realty/suburban/', data, files);
};

export const read = async (id: number): Promise<IRSUBModel> => {
    return await Api.get(`/realty/suburban/${id}`);
};

export const update = async (
    id: string | number,
    data: any,
    files?: Record<string, FileList | File[]>,
): Promise<IUpdateData<IRSUBModel>> => {
    return await Api.put<IUpdateData<any>>(
        `/realty/suburban/${id}`,
        data,
        files,
    );
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/realty/suburban/${id}`);
};

export const deleteFile = async (
    id: string | number,
    name: string,
): Promise<IFilestore | any> => {
    const res = await Api.delete<IFilestore>(
        `/realty/suburban/${id}/files/${name}`,
    );
    return res;
};

export const sold = async (id: string | number): Promise<any> => {
    return await Api.post(`/realty/suburban/sold/${id}`);
};

export const soldOff = async (id: string | number): Promise<any> => {
    return await Api.post(`/realty/suburban/sold-off/${id}`);
};

export const checkDouble = async (data: TCheckDoubleParams): Promise<any> => {
    if (data.cn && data.userUid) {
        return await Api.post('/realty/suburban/check-double', data);
    }
};

export const favorite = async (id: string | number): Promise<any> => {
    return await Api.post(`/realty/suburban/favorite/${id}`);
};

export const verify = async (id: string | number): Promise<any> => {
    return await Api.post(`/realty/suburban/verify/${id}`);
};

export const unverify = async (
    id: string | number,
    data: any,
): Promise<any> => {
    return await Api.post(`/realty/suburban/unverify/${id}`, data);
};

export const transfer = async (
    id: string | number,
    uid: string,
): Promise<IUpdateData<IRSUBModel & any>> => {
    return await Api.post(`/realty/suburban/transfer/${id}`, { userUid: uid });
};

export const countmyoutdatedsoon = async (): Promise<any> => {
    return await Api.get('/realty/suburban/countmyoutdatedsoon');
};

export const fresh = async (id: string | number): Promise<any> => {
    return await Api.post(`/realty/suburban/fresh/${id}`);
};

export const uploadPhotos = async (
    id: number,
    files: FileList | File[],
    config?: AxiosRequestConfig,
): Promise<IFilestore[]> => {
    console.dir(files);
    return await Api.post(
        `/realty/suburban/upload-photo/${id}`,
        null,
        { files },
        config,
    );
};

export const xmlfeedaction = async (
    id: string | number,
    data: Record<string, any>,
): Promise<any> => {
    return await Api.post(`/realty/suburban/xmlfeedaction/${id}`, data);
};

export type UploadProps = {
    files: File[] | FileList;
    id?: number;
    onUploadProgress?: (e) => void;
};

export type TCheckDoubleParams = {
    // addressPlaceDetailId: number;
    cn: string;
    userUid: string;
    id?: number;
};
