import { Link, useNavigate } from 'react-router-dom';
import React, { useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputField from '../Common/Form/InputField';
import { Field, Form as FinalForm } from 'react-final-form';
import { updateUser, selectUsers } from '~/src/store/usersSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Col, Form, Navbar, Row } from 'react-bootstrap';
import {
    required,
    onlyLength,
    composeValidators,
    mustBeNumber,
} from '~/src/helpers/validators';
import DatePickerField from '../Common/Form/DatePickerField/DatePickerField';
import ChangePassword from './ChangePassword';
import Confirm from '../Common/ModalUI/AlertModals/Confirm';
import { resetPassword } from '~/src/service/user';
import HaveAccess from '../Common/HaveAccess';
import CheckboxField from '../Common/Form/CheckboxField';

export default function RSUpdateForm(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);
    const [resetPasswordOpen, setResetPasswordOpen] = useState(false);

    // const [uploadFiles, setUploadFiles] = useState(null);
    // const [preview, setPreview] = useState([]);
    const { item, loading } = useSelector(selectUsers);

    const onSubmit = (values) => {
        // const filesRecord = uploadFiles === null ? undefined : { 'photosUpload': uploadFiles };

        dispatch(
            updateUser(+values?.id, {
                ...values,
            }),
        );

        // setPreview([]);
        // setUploadFiles(null);
    };

    const handleResetPasswordClick = async () => {
        await resetPassword(item.id);
        setResetPasswordOpen(false);
    };

    return (
        <React.Fragment>
            <Suspense fallback={<div>Loading...</div>}>
                {loading || item === null || item === undefined ? (
                    <div>Загрузка...</div>
                ) : (
                    <FinalForm
                        onSubmit={onSubmit}
                        initialValues={{
                            ...Object.keys(item)
                                .filter((k) => item[k] != null)
                                .reduce((a, k) => ({ ...a, [k]: item[k] }), {}),
                        }}
                        mutators={{
                            setValue: (
                                [field, value],
                                state,
                                { changeValue },
                            ) => {
                                changeValue(state, field, () => value);
                            },
                            setDateValue: (
                                [field, value],
                                state,
                                { changeValue },
                            ) => {
                                changeValue(state, field, () =>
                                    value.toString(),);
                            },
                        }}
                        validate={() => {
                            const errors: any = {};
                            return errors;
                        }}
                        render={({ handleSubmit, submitting, valid }) => (
                            <form onSubmit={handleSubmit}>
                                <Navbar
                                    className='justify-content-between'
                                    bg='light'
                                    expand='lg'
                                    variant='light'
                                >
                                    <ButtonGroup>
                                        <Button
                                            size='sm'
                                            type='button'
                                            onClick={() => navigate(-1)}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'fast-backward']}
                                            />{' '}
                                            Назад
                                        </Button>
                                        <Button
                                            size='sm'
                                            type='submit'
                                            className='btn btn-sm btn-success'
                                            disabled={submitting || !valid}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'save']}
                                            />{' '}
                                            Сохранить
                                        </Button>
                                    </ButtonGroup>
                                    <ButtonGroup>
                                        <Button
                                            size='sm'
                                            type='button'
                                            onClick={() =>
                                                setChangePasswordOpen(true)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'key']}
                                            />{' '}
                                            Сменить пароль
                                        </Button>
                                        <HaveAccess access='*' roles='grand'>
                                            <Button
                                                size='sm'
                                                variant='danger'
                                                type='button'
                                                onClick={() =>
                                                    setResetPasswordOpen(true)
                                                }
                                            >
                                                Сбросить пароль
                                            </Button>
                                            <Link
                                                className='btn btn-sm btn-warning'
                                                to={`/user/${item.id}/permission`}
                                            >
                                                Права пользователя
                                            </Link>
                                        </HaveAccess>
                                    </ButtonGroup>
                                </Navbar>
                                <Row>
                                    <Col>
                                        <InputField
                                            name='lastname'
                                            label='Фамилия'
                                        />
                                    </Col>
                                    <Col>
                                        <InputField
                                            name='firstname'
                                            label='Имя'
                                            validators={required}
                                        />
                                    </Col>
                                    <Col>
                                        <InputField
                                            name='middlename'
                                            label='Отчество'
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <DatePickerField
                                            name='birthdate'
                                            label='День рождения'
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputField
                                            name='email'
                                            label='Email'
                                            type='email'
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Field
                                            name='replacementPhone'
                                            validate={composeValidators(
                                                mustBeNumber,
                                                onlyLength(10),
                                            )}
                                        >
                                            {({ input, meta }) => (
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>
                                                        Подменный телефон{' '}
                                                        <small className='text-muted'>
                                                            (10 цифр без 8)
                                                        </small>
                                                    </Form.Label>
                                                    <Form.Control
                                                        {...input}
                                                        type='number'
                                                        size='sm'
                                                        isInvalid={
                                                            meta.error ||
                                                            meta.submitError
                                                        }
                                                        placeholder='Подменный телефон'
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {meta.error ||
                                                            meta.submitError}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                        <CheckboxField
                                            name='isReplacementPhoneActive'
                                            label='Активировать подменный номер'
                                        />
                                    </Col>
                                    <Col></Col>
                                </Row>
                                <Button
                                    size='sm'
                                    type='submit'
                                    className='btn btn-sm btn-success mb-2'
                                    disabled={submitting || !valid}
                                >
                                    <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                    Сохранить
                                </Button>
                            </form>
                        )}
                    />
                )}
            </Suspense>
            <ChangePassword
                isOpen={changePasswordOpen}
                onClose={() => setChangePasswordOpen(false)}
            />
            <Confirm
                text='Сбросить пароль?'
                onClose={() => setResetPasswordOpen(false)}
                isOpened={resetPasswordOpen}
                primaryButtonOnClick={handleResetPasswordClick}
                secondaryButtonOnClick={() => setResetPasswordOpen(false)}
            />
        </React.Fragment>
    );
}
